import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { Router, Link as RouterLink, Route, Redirect, Switch } from 'react-router-dom';
import history from './history'
import { Link, animateScroll as scroll } from 'react-scroll';
import './Infrastructure.scss';
import * as gfx from './gfx.js';
import * as content from './content.js';
import { CSSTransition } from 'react-transition-group';

const themes = ['Killed Person','Racial & Colonial Histories', 'Legal Background', 'Carceral','Medical','Policing','Legal Aftermath'];
const bgColors = ['#000','#ed254e','#eb8258','#bfb441','#218380','#2a6199','#9b3b88',"#fff"];
const bgDesat = ['#fff','#b28e8e','#b2988d','#b2ae8e','#8eb2b1','#8ea0b2','#b28eaa','#fff'];
const icons = [gfx.SvgGavel, gfx.SvgGun, gfx.SvgGavel, gfx.SvgCuffs, gfx.SvgSteth, gfx.SvgGun, gfx.SvgGavel];
const animTimeout = 200;
const basename = process.env.PUBLIC_URL;

class Section extends React.Component {
  render () {    
    const section = this.props.resource[this.props.subject].sections[this.props.index];
    if (section.length === 0) {
      return null
    } else {
      let title = "";
      if (this.props.title !== "Killed Person") {
        title = <h1>{this.props.title}</h1>;
      }
      return (
        <CSSTransition appear={this.props.exiting} in={this.props.exiting} timeout={animTimeout} classNames="section">
          <div className="section" id={this.props.id}>
            {title} {
              section.map( (subsec, index) => {
                return (
                  <SubSection 
                    key={index.toString()} 
                    content={subsec[0]} 
                    bgColor={this.props.bgColor} 
                  />
                )
              })
            }
            <div className="clear"></div>
            <span className="back-btn">
              <p style={{cursor:"pointer"}} onClick={()=> scroll.scrollTo(0,{duration:400,smooth:"easeOutCubic"})}>
                Back to Top
              </p>
            </span>
          </div>
        </CSSTransition>
      )
    }
  }
}

function SubSection (props) {
  const styles = {
    blockquote: {
      backgroundColor: props.bgColor
    }
  };
  return (
    <div style={styles}>
      {props.content}
    </div>
  )
}

function Arrow (props) {
  return (
    <li>
      <div className="circle arrow">
          <gfx.SvgArrow className="icons arrow" arrowangle={"rotate(" + props.angle}/>
      </div>
    </li>
  )
}

function CircleLink (props) {
  const struc = props.index;
  const circ = props.circ;
  const style = props.style;
  if ((style === "circle active" || style === "circle center active") && props.currentIndex === props.mstrIndex) {
    return (
      <Link 
        to={struc[1]}
        smooth={"easeOutCubic"} 
        duration={500} 
        offset={-10} 
        onClick={() => props.updateID(struc, circ, false)} 
        style={{cursor:"pointer"}}>
          {struc[0]}
      </Link>
    )
  } else {
    return <p>{struc[0]}</p>
  }
}

function Circle (props) {
  const circ = props.index;
  const theme = themes[circ];
  let style = "circle";
  if (circ === 0) { style = "circle center"}
  let cursor = "pointer";
  let sectionThemes = content.articleData[props.subject].structures[circ];
  //console.log("CURRENT INDEX: " + props.currentIndex)
  if (circ === props.currentIndex) {
    if (circ === 0) {
      style = "circle center active";
    } else {
      style = "circle active";
    }
    cursor = "default";
  }
  const link = (style) => {
    const ln = sectionThemes.length;
    if (ln === 0) {
      style = "circle inactive";
      return (
        <div className={style} style={{backgroundColor:bgColors[circ]}}> {
            React.createElement(
              icons[circ],
              {className: 'icons', fill: bgColors[circ]},
            )
          }
        </div>
      )
    } else {
      return (     
        <div onClick={() => props.updateID(theme, circ, true)} className={style} style={{backgroundColor:bgColors[circ], cursor:cursor}}> {
            React.createElement(
              icons[circ],
              {className: 'icons', fill: bgColors[circ]},
            )
          }
          <CSSTransition appear={props.exiting} in={props.exiting} timeout={animTimeout} classNames="circle-text">
            <div className="circle-text">
              <div className="circle-links"> {
                  sectionThemes.map( (struc) => {
                    return (
                      <CircleLink 
                        index = {struc}
                        key = {struc}
                        circ = {circ}
                        style = {style}
                        currentIndex = {props.currentIndex}
                        mstrIndex = {props.mstrIndex}
                        updateID = {props.updateID}
                      />
                    )
                  })
                }
              </div>
            </div>
          </CSSTransition>
        </div>
      );
    }
  };
  if (circ === 0) {
    return link(style);
  } else {
    return (
      <li key={theme}>
        {link(style)}
      </li>
    );
  }
}

class Diagram extends React.Component {       
  render () {            
    return (
      <div>
        <div className="title">
          <h1 id="top">Racial Infrastructure</h1>   
        </div>
        <div className="circle-container">
          <div className="hexagon">
            <div className="hexagon inner" /*style={{backgroundColor: this.props.bgColor, borderLeftColor: this.props.bgColor, borderRightColor: this.props.bgColor}}*/></div>
          </div> 
          <div className="arrow-container"> {
            [0,1,2,3,4,5].map( (circ) => {
              return (<Arrow 
                angle = {210 + (60 * circ)}
                key = {circ.toString()}
              />)
            })
          }
          </div> {
            [0,1,2,3,4,5,6].map( (circ) => {
              return (
                <Circle 
                  index = {circ}
                  key = {circ.toString()}
                  updateID = {this.props.updateID}
                  subject = {this.props.subject}            
                  bgColor = {this.props.bgColor}
                  currentIndex = {this.props.currentIndex}
                  mstrIndex = {this.props.mstrIndex}
                  exiting = {this.props.exiting}
                />
              )
            })
          }
        </div>
      </div>
    );
  }
}

function Header (props) {  
  const menu = content.siteMenu.map( (cat, index) => {
    let style;
    cat === props.activeCat ? style = "header-links-active" : style = "header-links";
    //console.log(history.location.pathname);
    if (cat === "case-studies") {
      return (
        <RouterLink key={cat.id} onClick={() => props.onClick(cat.id, true)} className="router-link" to={history.location.pathname}>
          <li className="header-li">{cat.name}</li>
        </RouterLink>
      )
    } else {
      return (
        <RouterLink key={cat.id} onClick={() => props.onClick(cat.id, true)} className="router-link" to={history.location.pathname}>
          <li className={style}>{cat.name}</li>
        </RouterLink>
      )
    }
  })
  return (
    <div>
      <h1 className="page-title">
        <RouterLink onClick={ () => props.onClick("home", true)} className="router-link" to={history.location.pathname}>
          RACE AND DEATHS IN CUSTODY
        </RouterLink>
      </h1>
      <div className="header-menu">
        {menu}
      </div> 
    </div>
  )
}

function ListofCases (props) {
  return (
    <React.Fragment>
      <h1 className="title mid">Case Studies</h1>
      <div className="sidebar-map">
        {
          Object.keys(content.articleData).map ( (art, index)=> {
            let style;
            art === props.activeSubj ? style = "active" : style = "";
            return ( 
              <p>
                <RouterLink className={"sidebar-map " + style} to={history.location.pathname} onClick={() => props.onClick(art, true)}>
                  {content.articleData[art].name}
                </RouterLink>
              </p>
              )
          })
        }
      </div>
    </React.Fragment>
  )
}

function ResourceMap (props) {
  return (
    content.resourcesData["resources"].themes.map( (key, index)=> {
      if (index > 0) {
        let style;
        //console.log(index, props.activeIndex);
        index === props.activeIndex ? style = "active" : style = "";
        return (
          <p>
            <RouterLink onClick={ () => props.onClick(key.id, index, true)} className={"sidebar-map " + style} to={history.location.pathname}>
              {key.name}
            </RouterLink>
          </p>
        )
      }
    })
  )
}

function GlossaryMap (props) {
  return (
    content.glossaryData["glossary"].themes.map( (key, index)=> {
      if (index > 0) {
        let style;
        //console.log(index, props.activeIndex);
        index === props.activeIndex ? style = "active" : style = "";
        return (
          <p>
            <RouterLink onClick={ () => props.onClick(key.id, index, true)} className={"sidebar-map " + style} to={history.location.pathname}>
              {key.name}
            </RouterLink>
          </p>
        )
      }
    })
  )
}

function RelatedMap (props) {
  return (
    content.relatedData["related"].themes.map( (key, index)=> {
      if (index > 0 && index < 6) {
        let style;
        //console.log(index, props.activeIndex);
        index === props.activeIndex ? style = "active" : style = "";
        return (
          <p>
            <RouterLink onClick={ () => props.onClick(key.id, index, true)} className={"sidebar-map " + style} to={history.location.pathname}>
              {key.name}
            </RouterLink>
          </p>
        )
      }
    })
  )
}

function RelatedAusMap (props) {
  return (
    content.relatedData["related"].themes.map( (key, index)=> {
      if (index > 5) {
        let style;
        //console.log(index, props.activeIndex);
        index === props.activeIndex ? style = "active" : style = "";
        return (
          <p>
            <RouterLink onClick={ () => props.onClick(key.id, index, true)} className={"sidebar-map " + style} to={history.location.pathname}>
              {key.name}
            </RouterLink>
          </p>
        )
      }
    })
  )
}

function BiblioMap (props) {
  return (
    content.biblioData["bibliography"].themes.map( (key, index)=> {
      if (index > 0) {
        let style;
        //console.log(index, props.activeIndex);
        index === props.activeIndex ? style = "active" : style = "";
        return (
          <p>
            <RouterLink onClick={ () => props.onClick(key.id, index, true)} className={"sidebar-map " + style} to={history.location.pathname}>
              {key.name}
            </RouterLink>
          </p>
        )
      }
    })
  )
}

class Infrastructure extends React.Component { 
  constructor (props) {
    super(props);
    this.state = {
      subject: "tutorial",
      category: "racial-infrastructure",
      activeID: "Killed Person",
      activeIndex: 0,
      activeElement: null,
      showArticle: true,
      showDiagram: true,
      showCategory: true,
      diagramIndex: 0,
      bodyBgColor: bgDesat[7],
      article: "",
      toc: null,
    };
    this.updateActiveID = this.updateActiveID.bind(this);
    this.changeArticle = this.changeArticle.bind(this);
    this.triggerCatUpdate = this.triggerCatUpdate.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.triggerArticleUpdate = this.triggerArticleUpdate.bind(this);
    this.triggerIDUpdate = this.triggerIDUpdate.bind(this);
    this.revertState = this.revertState.bind(this);
    this.updateStatefromPath = this.updateStatefromPath.bind(this);
  }

  changeArticle (subj, push) {
    this.setState({
      subject: subj,
    });
    this.updateActiveID("Killed Person", 0, false);
    if (push) {
      history.replace({
        pathname: basename + "/" + this.state.category + "/" + this.state.subject,
        state: { category: this.state.category, subject: this.state.subject, activeID: this.state.activeID, activeIndex: this.state.activeIndex }
      })
    }
    if (window.pageYOffset > 80) {
      window.scrollTo(0, 80);
    }
    window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }

  triggerArticleUpdate(subj, push) {
    if (this.state.showArticle) {
      let temp_subj = subj
      if (subj === this.state.subject) {
        temp_subj = this.state.subject
      }
      this.setState({
        showArticle: false,
        showDiagram: false,
        diagramIndex: 0,
      })
      setTimeout(
        () => this.changeArticle(temp_subj, push),
        animTimeout
      );
    }
  }

  triggerIDUpdate (id, index, push) {
    //console.log(id, index, push)
    if (this.state.showArticle && index !== this.state.activeIndex) {
      this.setState({
        showArticle: false,
        diagramIndex: index,
      });
      setTimeout(
        () => this.updateActiveID(id, index, push),
        animTimeout
      );
    }
  }

  updateActiveID (id, index, push) {
    this.setState({
      activeID: id,
      activeIndex: index,
      activeElement: null,
      bodyBgColor: bgDesat[index],
      showArticle: true,
      showDiagram: true,
    });
    if (push) {
      //console.log(basename + "/" + this.state.category + "/" + this.state.subject)
      let path;
      if (this.state.category === "case-studies" || this.state.category === "racial-infrastructure") {
        path = basename + "/" + this.state.category + "/" + this.state.subject;
      } else {
        path = basename + "/" + this.state.category;
      }
      history.push({
        pathname: path,
        state: { category: this.state.category, subject: this.state.subject, activeID: this.state.activeID, activeIndex: this.state.activeIndex }
      })
      if (window.pageYOffset > 80) {
        window.scrollTo(0, 80);
      }
      window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }  
  }

  changeCategory (cat, push) {
    this.setState({
      category: cat,
      showArticle: true,
      showCategory: true,
    });
    if (push) {
      let path = basename + '/' + cat;
      if (cat === "case-studies" || cat === "racial-infrastructure") {
        let temp_subj;
        cat === "case-studies" ? temp_subj = "bushie" : temp_subj = "tutorial"; 
        this.setState ({
          subject: temp_subj,
          activeID: "Killed Person",
          activeIndex: 0,
          activeElement: null,
          showArticle: true,
          showDiagram: true,
        })
        path = basename + '/' + cat + '/' + this.state.subject;
      } else if (cat === "resources" || cat === "related") {
        this.setState ({
          activeIndex: 0,
        })
      }
      history.replace({
        pathname: path,
        state: { category: this.state.category, subject: this.state.subject, activeID: this.state.activeID, activeIndex: this.state.activeIndex }
      })
    }
  }

  triggerCatUpdate (cat, push) {
    if (this.state.showArticle && cat !== this.state.category) {
      let temp_cat = cat
      if (cat === this.state.category) {
        temp_cat = this.state.category
      }
      this.setState({
        showArticle: false,
        showDiagram: false,
        showCategory: false,
        diagramIndex: 0,
      })
      setTimeout(
        () => this.changeCategory(temp_cat, push),
        animTimeout
      );
    }
  }

  updateStatefromPath (path) {
    let pathRegex = /\/([\w-]*)\/?([\w-]*)\/?([\w-]*)?/;
    const res = pathRegex.exec(path.toString());
    console.log(res);
    
    if (!res) { 
      console.log("res is null; returning")
      return 
    }
    if (res[1] === "") {
      res[1] = res[2]
      if (res[3]) {
        res[2] = res[3];
        res[3] = null;
      }
      console.log(res);
    }
    if (res[1]) {
      this.state.category = res[1];
      console.log("category set to", this.state.category);
    }

    if (res[2]) {
      const subjs = Object.keys(content.articleData).map ( (art)=> {
        return content.articleData[art].id;
      })
      console.log(subjs);
      let pass = false;
      for (let i = 0; i < subjs.length; i++) {
        if (res[2] === subjs[i])  {
          pass = true;
        }
      }
      if (pass) {
        this.state.subject = res[2];
        console.log("subj set to", this.state.subject);
      } else {
        console.log("no subject of that name found");
      }
    }
  }

  componentDidMount() {
    console.log(history.location.pathname);
    if (history.location.pathname !== basename + '/') {
      this.updateStatefromPath(history.location.pathname);
    }
    this.changeCategory(this.state.category, false)
    this.changeArticle(this.state.subject, false);
    this.triggerIDUpdate(this.state.activeID, this.state.activeIndex, false);
    this.updateActiveID(this.state.activeID, this.state.activeIndex, false);
    let path = basename + '/' + this.state.category;
    if (this.state.category === "case-studies" || this.state.category === "racial-infrastructure") {
      path = basename + '/' + this.state.category + '/' + this.state.subject;
    }
    history.replace({
      pathname: path,
      state: { category: this.state.category, subject: this.state.subject, activeID: this.state.activeID, activeIndex: this.state.activeIndex }
    });
    window.addEventListener("popstate", this.revertState);
    //console.log("component mounted");
  }

  revertState () {
    const location = history.location;
    if (location.state) {
      this.changeCategory(history.location.state.category, false)
      this.changeArticle(history.location.state.subject, false);
      this.triggerIDUpdate(history.location.state.activeID, history.location.state.activeIndex, false);
      this.updateActiveID(history.location.state.activeID, history.location.state.activeIndex, false);
    }
  }

  render() {       
    return (
      <Router history={history} basename={basename}>
      <div className="container">
        <div className="header">
          <Header 
            activeCat = {this.state.category}
            onClick = {this.triggerCatUpdate}
          />
        </div>
        <CSSTransition appear={this.state.showCategory} in={this.state.showCategory} timeout={animTimeout} classNames="section">
          <div className="content-container">
          <Switch>
            <Route path={basename + "/case-studies/:artID"} render={ () => (
                <React.Fragment>
                  <div className="diagram-container">                
                    <Diagram 
                      subject = {this.state.subject}            
                      bgColor = {this.state.bodyBgColor}
                      currentIndex = {this.state.diagramIndex}
                      mstrIndex = {this.state.activeIndex}
                      updateID = {this.triggerIDUpdate}
                      exiting = {this.state.showDiagram}
                    />
                    <ListofCases 
                      activeSubj = {this.state.subject}
                      onClick = {this.triggerArticleUpdate}
                    />
                  </div>
                  <div className="article-container">
                    <div className="article">
                      <Section 
                        subject = {this.state.subject}
                        index = {this.state.activeIndex}
                        id = {this.state.activeID}
                        bgColor = {this.state.bodyBgColor}
                        resource = {content.articleData}
                        key = {this.state.activeID}
                        title = {themes[this.state.activeIndex]}
                        exiting = {this.state.showArticle}
                        active = {this.state.activeElement}
                      />
                    </div>
                  </div>
                </React.Fragment>
            )}>
            </Route>
            <Route path={basename + "/racial-infrastructure/:artID"} render={ () => (
                <React.Fragment>
                  <div className="diagram-container">                
                    <Diagram 
                      subject = {this.state.subject}            
                      bgColor = {this.state.bodyBgColor}
                      currentIndex = {this.state.diagramIndex}
                      mstrIndex = {this.state.activeIndex}
                      updateID = {this.triggerIDUpdate}
                      exiting = {this.state.showDiagram}
                    />
                  </div>
                  <div className="article-container">
                    <div className="article">
                      <Section 
                        subject = {this.state.subject}
                        index = {this.state.activeIndex}
                        id = {this.state.activeID}
                        bgColor = {this.state.bodyBgColor}
                        key = {this.state.activeID}
                        resource = {content.articleData}
                        title = {themes[this.state.activeIndex]}
                        exiting = {this.state.showArticle}
                        active = {this.state.activeElement}
                      />
                    </div>
                  </div>
                </React.Fragment>
            )}>
            </Route>
            <Route path={basename + "/glossary"} render={ () => (
              <React.Fragment>
              <div className="diagram-container">
              <div className="title no-pad">
                <RouterLink className="router-link" onClick={ ()=> this.triggerIDUpdate("glossary", 0, false)} to={history.location.pathname}>
                  <h1 id="top">Glossary</h1>
                </RouterLink>
              </div>
                <div className='sidebar-map'>
                  <GlossaryMap 
                    onClick = {this.triggerIDUpdate}
                    activeIndex = {this.state.activeIndex}
                  />
                </div>
              </div>
              <div className="article-container">
                <div className="article">
                  <Section 
                      subject = {"glossary"}
                      index = {this.state.activeIndex}
                      id = {this.state.activeID}
                      bgColor = {this.state.bodyBgColor}
                      key = {this.state.activeID}
                      resource = {content.glossaryData}
                      title = {content.glossaryData["glossary"].themes[this.state.activeIndex].name}
                      exiting = {this.state.showArticle}
                      active = {this.state.activeElement}
                  />
                </div>
              </div>
            </React.Fragment>
            )}>
            </Route>
            <Route path={basename + "/home"} render={ () => (
              <div className="article-container">
                <div className="article">
                  <h1>Purpose</h1>
                  {content.homeData.sections} 
                </div>
              </div>
            )}>
            </Route>
            <Route path={basename + "/resources"} render={ () => (
              <React.Fragment>
                <div className="diagram-container">
                <div className="title no-pad">
                  <RouterLink className="router-link" onClick={ ()=> this.triggerIDUpdate("resources", 0, false)} to={history.location.pathname}>
                    <h1 id="top">Resources</h1>
                  </RouterLink>
                </div>
                  <div className='sidebar-map'>
                    <ResourceMap 
                      onClick = {this.triggerIDUpdate}
                      activeIndex = {this.state.activeIndex}
                    />
                  </div>
                </div>
                <div className="article-container">
                  <div className="article">
                    <Section 
                        subject = {"resources"}
                        index = {this.state.activeIndex}
                        id = {this.state.activeID}
                        bgColor = {this.state.bodyBgColor}
                        key = {this.state.activeID}
                        resource = {content.resourcesData}
                        title = {content.resourcesData["resources"].themes[this.state.activeIndex].name}
                        exiting = {this.state.showArticle}
                        active = {this.state.activeElement}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}>
            </Route>
            <Route path={basename + "/related"} render={ () => (
              <React.Fragment>
              <div className="diagram-container">
                <div className="title no-pad">
                  <RouterLink className="router-link" onClick={ ()=> this.triggerIDUpdate("related", 0, false)} to={history.location.pathname}>
                    <h1 id="top">Related Cases - Canada</h1>
                  </RouterLink>
                </div>
                <div className='sidebar-map'>
                  <RelatedMap 
                    onClick = {this.triggerIDUpdate}
                    activeIndex = {this.state.activeIndex}
                  />
                </div>
                <div className="title no-pad">
                  <RouterLink className="router-link" onClick={ ()=> this.triggerIDUpdate("related", 0, false)} to={history.location.pathname}>
                    <h1 id="top">Related Cases - Australia</h1>
                  </RouterLink>
                </div>
                <div className='sidebar-map'>
                  <RelatedAusMap 
                    onClick = {this.triggerIDUpdate}
                    activeIndex = {this.state.activeIndex + content.relatedData.related.themes.length}
                  />
                </div>
              </div>
              <div className="article-container">
                <div className="article">
                  <Section 
                      subject = {"related"}
                      index = {this.state.activeIndex}
                      id = {this.state.activeID}
                      bgColor = {this.state.bodyBgColor}
                      key = {this.state.activeID}
                      resource = {content.relatedData}
                      title = {content.relatedData["related"].themes[this.state.activeIndex].name}
                      exiting = {this.state.showArticle}
                      active = {this.state.activeElement}
                  />
                </div>
              </div>
            </React.Fragment>
            )}>
            </Route>
            <Route path={basename + "/bibliography"} render={ () => (
              <React.Fragment>
              <div className="diagram-container">
              <div className="title no-pad">
                <RouterLink className="router-link" onClick={ ()=> this.triggerIDUpdate("bibliography", 0, false)} to={history.location.pathname}>
                  <h1 id="top">By Subject</h1>
                </RouterLink>
              </div>
                <div className='sidebar-map'>
                  <BiblioMap 
                    onClick = {this.triggerIDUpdate}
                    activeIndex = {this.state.activeIndex}
                  />
                </div>
              </div>
              <div className="article-container">
                <div className="article">
                  <Section 
                      subject = {"bibliography"}
                      index = {this.state.activeIndex}
                      id = {this.state.activeID}
                      bgColor = {this.state.bodyBgColor}
                      key = {this.state.activeID}
                      resource = {content.biblioData}
                      title = {content.biblioData["bibliography"].themes[this.state.activeIndex].name}
                      exiting = {this.state.showArticle}
                      active = {this.state.activeElement}
                  />
                </div>
              </div>
            </React.Fragment>
            )}>
            </Route>
            <Redirect to={basename + "/home"}/>
          </Switch>
          </div>
        </CSSTransition>
        <div className="clear"></div>
      </div>
      </Router>
    );
  }
}

// ========================================

/*ReactDOM.render(
  <Infrastructure />,
  document.getElementById('root')
)*/

export default Infrastructure;